<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->
      <div align-h="between">
        <b-row>
          <b-col
            cols="12"
            class="pb-2"
          >
            <b-form-radio-group
              v-model="searchType"
              button-variant="outline-primary"
              :options="optionsRadio"
              buttons
              size="sm"
              name="radios-btn-default"
            />
          </b-col>
        </b-row>

        <b-row align-h="between">
          <!-- Search -->
          <b-col
            v-if="searchType === 'name'"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-start">
              <vue-autosuggest
                v-model="query"
                :suggestions="filteredOptions"
                :input-props="inputProps"
                :disabled="!storeId"
                :get-suggestion-value="getSuggestionValue"
                :limit="10"
                @selected="onSelected"
                @click="clickHandler"
                @input="onInputChange"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.itemArabicName }}</span>
                </template>
              </vue-autosuggest>
            </div>
          </b-col>
          <b-col
            v-if="searchType === 'barcode'"
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter.barcode"
                class="d-inline-block mr-1"
                :clearable="true"
                :disabled="!storeId"
                :placeholder="$t('search')"
                @keydown.enter.prevent="getItemWithBarcode"
                @dblclick="() => $refs.searchItemsModal.openModal(transactionType)"
              />
            </div>
          </b-col>
          <b-col
            md="2"
            class="d-flex justify-content-end"
          >
            <b-button
              variant="gradient-primary"
              :disabled="!storeId"
              size="sm"
              data-action-type
              @click="(v) => {$refs.searchItemsModal.openModal(transactionType);}"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              {{ $t('addItems') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-table
      ref="g-table"
      :items="items"
      :fields="columns"
      primary-key="barcode"
      show-empty
      hover
      small
      responsive
      foot-clone
      sticky-column
      :per-page="items.length"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      :sort-direction="sortDirection"
      class="mb-0"
      :empty-text="$t('noMatchingRecordsFound')"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
        >
      </template>
      <template #head(actions)>
        <span />
      </template>
      <template #cell(mainUnitId)="{ item }">
        <g-field
          field="select"
          item-id="unitId"
          placeholder="unit"
          class="select-size-sm my-0"
          :disabled="!!item.transactionId"
          :value.sync="item.unitId"
          :options="item.units"
          :label="isRight ? 'unitArabicName' : 'unitEnglishName'"
          :dir="isRight ? 'rtl' : 'ltr'"
          @change="(v) => {
            item.price = v.salesPrice;
            item.costPrice = v.costPrice;
            computeTotal({ item: item });
            $emit('validate-balance');
          }"
        />
      </template>
      <template #cell(arabicName)="{ item }">
        <span style="min-width: 150px;"> {{ item.arabicName }}</span>
      </template>
      <template #cell(discount)="{ item }">
        <b-row>
          <b-col
            cols="6"
            class="p-0 pr-1"
          >
            <g-field
              :value.sync="item.discountValue"
              placeholder="value"
              type="number"
              size="sm"
              class="my-0"
              @input="
                (v) => {
                  computeTotal({
                    item: item,
                    discountValue: item.discountValue,
                  });
                }
              "
            />
          </b-col>
          <b-col
            cols="6"
            class="p-0"
          >
            <g-field
              :value.sync="item.discountPercentage"
              placeholder="%"
              type="number"
              size="sm"
              class="my-0"
              @input="
                (v) => {
                  computeTotal({
                    item: item,
                    discountPercentage: item.discountPercentage,
                  });
                }
              "
            >
              <template #append>
                <b-input-group-text> % </b-input-group-text>
              </template>
            </g-field>
          </b-col>
        </b-row>
      </template>

      <template #cell(quantity)="{ item }">
        <g-field
          :value.sync="item.quantity"
          type="number"
          :rules="`required|min_value:0`"
          :tool-tip-error="true"
          size="sm"
          class="my-0"
          @input="
            (v) => {
              computeTotal({ item: item });
            }
          "
          @change="(v) => { $emit('validate-balance'); }"
        />
      </template>

      <template #cell(costPrice)="{ item }">
        <g-field
          :value.sync="item.costPrice"
          type="number"
          rules="required"
          :short-desc="true"
          size="sm"
          class="my-0"
          @input="
            (v) => {
              computeTotal({ item: item });
            }
          "
          @change="() => { $emit('validate-salesPrice'); }"
        />
      </template>
      <template #cell(actions)="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            data-action-type="delete"
            variant="flat-danger"
            size="sm"
            @click="deleteItem(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="mx-0 clickable danger"
            />
          </b-button>
        </div>
      </template>
      <template
        #foot()
      >
        <span />
      </template>
      <template #foot(quantity)>
        <span> {{ fraction(totalQuantity) }} </span>
      </template>
      <template #foot(total)>
        <span> {{ fraction(totalPrices) }} </span>
      </template>
      <template #foot(discount)>
        <span> {{ fraction(totalDiscountValue) }} </span>
      </template>
      <template #foot(net)>
        <span> {{ fraction(totalNetPrice) }} </span>
      </template>
    </b-table>
    <search-items
      ref="searchItemsModal"
      :store-id="storeId"
      :destination-store-id="destinationStoreId"
      @addNewItems="addItems"
    />
    <p class="d-none">
      {{ isDefaultStore }}
    </p>
  </div>
</template>

<script>
import searchItems from '@/pages/Shared/SearchItems.vue';

export default {
  components: {
    searchItems,
  },
  emits: ['update:items', 'validate-balance', 'validate-salesPrice'],
  props: {
    items: {
      type: [Array, Function],
      default: () => [],
    },
    storeId: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
    destinationStoreId: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
    transactionType: {
      type: [String, Number, null],
      required: false,
      default: null,
    }
  },
  data() {
    return {
      perPage: 25,
      searchQuery: '',
      sortDirection: 'asc',
      isSortDirDesc: '',
      currentPage: 1,
      sortBy: '',
      filterOn: [],
      totalRows: 0,
      filter: {
        barcode: '',
      },
      error: {},
      totalQuantity: 0.00,
      totalPrice: 0.00,
      totalPrices: 0.00,
      totalDiscountValue: 0.00,
      totalNetPrice: 0.00,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: `${this.$t('search')}`,
      },
      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      query: '',
      searchType: 'barcode',
      currentItems: [],
    };
  },
  computed: {
    optionsRadio() {
      return [
        { text: this.$t('barcode'), value: 'barcode' },
        { text: this.$t('name'), value: 'name' },
      ];
    },
    isDefaultStore() {
        if (this.currentBranch.defaultStoreId) {
          this.cachedItems({
            currentPage: 1,
            perPage: 2000,
          });
        return true;
      } return false;
    },
    columns() {
      return [
        {
          key: 'lineSerial',
          label: '',
          sortable: false,
          thStyle: { width: '10px' },
        },
        {
          key: 'itemCode',
          sortable: false,
          label: this.$t('code'),
          thStyle: { width: '10px' },
        },
        {
          key: this.isRight ? 'itemArabicName' : 'itemEnglishName',
          label: this.$t('name'),
          sortable: false,
          thStyle: { width: '300px' },
          tdClass: ['nameTd', 'td-width'],
        },
        {
          key: 'mainUnitId',
          label: this.$t('unit'),
          sortable: false,
          thStyle: { width: '15%' },
          tdClass: ['td-width'],
        },
        {
          key: 'quantity',
          label: this.$t('quantity'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'costPrice',
          label: this.$t('costPrice'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: false,
          thStyle: { width: '200px' },
        },
        {
          key: 'actions',
          sortable: false,
          thStyle: { width: '200px' },
        },
      ];
    },
  },
  watch: {
    items() {
      this.getSummary();
    },
  },
  methods: {
    cachedItems({ currentPage, perPage }) {
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&itemType=sales&storeId=${this.currentBranch.defaultStoreId}&`;
      // params += this.getFilterObj(this.filter);
      this.get({ url: `items${params}` }).then(({ data }) => {
        this.cachedArray = data;
        window.sessionStorage.setItem('cachedArray', JSON.stringify(this.cachedArray));
        // this.totalCount = totalCount;
      });
    },
    clickHandler() {
    },
    onSelected(option) {
      // this.query = '';
      this.addItems([option.item]);
      this.query = '';
      this.filteredOptions = [];
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.itemArabicName;
    },
    demo() {
    var dataCached = JSON.parse(sessionStorage.getItem('cachedArray'));
    return Promise.resolve(dataCached);
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.filteredOptions = [];
        return
      }
      var itemArr = [];
      if (JSON.parse(sessionStorage.getItem('cachedArray'))) {
        this.demo().then((data) => {
          data.forEach((item) => {
        if (!item || !item.id) return;
        const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
            item.currentBalance = store.currentBalance;
            item.costPrice = store.costPrice;
            item.units = item.subUnits.map((unit) => {
              return {
                ...unit,
                itemId: item.id,
                costPrice: store.costPrice * unit.conversionRate,
              };
            });
        const units = item.subUnits;
        units.push({
          unitId: item.mainUnitId,
          unitArabicName: item.mainUnitArabicName,
          unitEnglishName: item.mainUnitEnglishName,
          salesPrice: item.salesPrice,
          minSalesPrice: item.minSalesPrice,
          conversionRate: 1,
          maxSalesPrice: item.maxSalesPrice,
          barcode: item.mainUnitBarcode,
          isMain: true,
        });
        itemArr.push({
            itemArabicName: item.arabicName,
            itemEnglishName: item.englishName,
            unitId: item.matchedUnitId || item.mainUnitId,
            code: item.code,
            itemCode: item.code,
            storeId: this.storeId,
            itemId: item.id,
            quantity: 1,
            costPrice: store.costPrice,
            convertedBalance: item.currentBalance / (units.find((u) => u.unitId === item.mainUnitId).conversionRate || 1),
            price: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            mainPrice: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            discountPercentage: 0,
            discountValue: 0,
            total: 0,
            net: 0,
            stores: item.stores,
            isTaxable: item.isTaxable,
            barCode: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode,
            matchedUnitId: item.matchedUnitId,
            units,
          })
        })
        if (data && data.length > 0) {
          return itemArr;
        }
        }).then((data) => {
        if (data && data.length > 0) {
          const filteredItemsData = data.filter(item => item.itemArabicName.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
          const filteredData = filteredItemsData;
          this.filteredOptions = [{
            data: filteredData,
          }]
        } else {
          this.filteredOptions = [{
            data: [],
          }]
        }
      })
      } else {
        this.get({ url: `items?arabicNamePart=${text}&WholeWord=false&storeId=${this.storeId}&pageSize=25` }).then(({ data }) => {
        data.forEach((item) => {
        if (!item || !item.id) return;
        const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
            item.currentBalance = store.currentBalance;
            item.costPrice = store.costPrice;
            item.units = item.subUnits.map((unit) => {
              return {
                ...unit,
                itemId: item.id,
                costPrice: store.costPrice * unit.conversionRate,
              };
            });
        const units = item.subUnits;
        units.push({
          unitId: item.mainUnitId,
          unitArabicName: item.mainUnitArabicName,
          unitEnglishName: item.mainUnitEnglishName,
          salesPrice: item.salesPrice,
          minSalesPrice: item.minSalesPrice,
          conversionRate: 1,
          maxSalesPrice: item.maxSalesPrice,
          barcode: item.mainUnitBarcode,
          isMain: true,
        });
        itemArr.push({
            itemArabicName: item.arabicName,
            itemEnglishName: item.englishName,
            unitId: item.matchedUnitId || item.mainUnitId,
            code: item.code,
            itemCode: item.code,
            storeId: this.storeId,
            itemId: item.id,
            quantity: 1,
            costPrice: store.costPrice,
            convertedBalance: item.currentBalance / (units.find((u) => u.unitId === item.mainUnitId).conversionRate || 1),
            price: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            mainPrice: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).salesPrice : item.salesPrice,
            discountPercentage: 0,
            discountValue: 0,
            total: 0,
            net: 0,
            stores: item.stores,
            isTaxable: item.isTaxable,
            barCode: item.matchedUnitId ? units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode,
            matchedUnitId: item.matchedUnitId,
            units,
          })
        })
          if (data && data.length > 0) {
            return itemArr;
          }
      }).then((data) => {
        if (data && data.length > 0) {
          const filteredItemsData = data.filter(item => item.itemArabicName.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
          const filteredData = filteredItemsData;
          this.filteredOptions = [{
            data: filteredData,
          }]
        } else {
          this.filteredOptions = [{
            data: [],
          }]
        }
        });
      }
    },
    deleteItem(item) {
      const filteredItems = this.items.reduce((items, val) => {
        if (val !== item) {
          const index = items.length > 0 ? items[items.length - 1].lineSerial : 0;
          val.lineSerial = index + 1;
          items.push(val);
        }
        return items;
      }, []);
      this.$emit('update:items', filteredItems);
    },
    getItemWithBarcode() {
      if (!this.storeId || !this.destinationStoreId || !this.filter.barcode) return false;
      this.get({ url: `items?barcode=${this.filter.barcode}&WholeWord=true&storeId=${this.storeId}&pageSize=1` }).then(({ data }) => {
        const item = data[0];
        if (!item || !item.id) return;
        const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
        const units = item.subUnits.map((unit) => {
              return {
                ...unit,
                itemId: item.id,
                costPrice: store.costPrice * unit.conversionRate,
              };
            });
        units.push({
          unitId: item.mainUnitId,
          unitArabicName: item.mainUnitArabicName,
          unitEnglishName: item.mainUnitEnglishName,
          salesPrice: item.salesPrice,
          minSalesPrice: item.minSalesPrice,
          maxSalesPrice: item.maxSalesPrice,
          barcode: item.mainUnitBarcode,
          costPrice: store.costPrice,
          isMain: true,
        });
        if (data && data.length > 0) {
          this.addItems([{
            itemArabicName: item.arabicName,
            itemEnglishName: item.englishName,
            unitId: item.mainUnitId,
            itemCode: item.code,
            storeId: this.storeId,
            itemId: item.id,
            quantity: 1,
            price: item.salesPrice,
            discountPercentage: 0,
            discountValue: 0,
            total: 0,
            net: 0,
            stores: item.stores,
            units,
            costPrice: item.stores.filter(x => x.storeId === this.storeId).map(y => y.costPrice)[0],
          }]);
        }
        this.filter.barcode = '';
      });
      return false;
    },
    getSummary() {
      this.totalQuantity = 0;
      this.totalPrices = 0;
      this.totalNetPrice = 0;
      this.totalDiscountValue = 0;

      this.items.forEach((item) => {
        this.totalQuantity += parseFloat(item.quantity) || 0;
        this.totalPrices += parseFloat(item.total) || 0;
        this.totalNetPrice += parseFloat(item.net) || 0;
        this.totalDiscountValue += parseFloat(item.discountValue) || 0;
      });
    },
    computeTotal({ item }) {
      item.total = item.costPrice * item.quantity;
      item.net = item.total;
      // if (discountPercentage >= 0) {
      //   item.discountValue = (item.total * (discountPercentage / 100)).toFixed(2);
      //   item.net -= (item.total * discountPercentage) / 100;
      // } else if (discountValue >= 0) {
      //   item.discountPercentage = item.total > 0 ? ((discountValue / item.total) * 100).toFixed(2) : 0;
      //   item.net -= (discountValue || 0);
      // } else {
      //   item.net -= item.discountValue || 0;
      //   item.discountPercentage = item.total > 0 ? ((discountValue / item.total) * 100).toFixed(2) : 0;
      // }
      this.getSummary();
    },
    addItems(newItems) {
      newItems.forEach((item) => {
        const existItem = this.items.find((val) => val.itemId === item.itemId && val.unitId === item.unitId);
        if (!existItem) {
          item.total = item.costPrice * item.quantity;
          item.net = item.total;
          this.items.push({
            ...item,
            lineSerial: this.items.length + 1,
          });
        } else {
          existItem.quantity = item.quantity;
          this.computeTotal({ item: existItem });
        }
        return true;
      });
      this.getSummary();
      this.$emit('validate-balance');
    },
    refreshTable() {
      this.$refs['g-table'].refresh();
    },
  },
};
</script>

<style lang="scss">
  .nameTd {
    white-space: nowrap !important;
  }
  #autosuggest {
    width: 75% !important;
  }
  .autosuggest__results-container .autosuggest__results {
      background-color: #fff;
      margin-top: 1rem;
      border-radius: 0.5rem;
      -webkit-box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
      box-shadow: 0 15px 30px 0 rgba(0,0,0,.11),0 5px 15px 0 rgba(0,0,0,.08)!important;
      position: absolute;
      width: 71%;
      overflow-y: auto;
      max-height: 40vh;
      z-index: 999;
  }
  .autosuggest__results-container .autosuggest__results ul li:hover {
      background: #ededed;
      cursor: pointer;
  }
  .autosuggest__results-container .autosuggest__results ul li {
      list-style: none;
      padding: 0.75rem 1rem;
      cursor: pointer;
  }
  .autosuggest__results-container .autosuggest__results ul {
      padding-left: 0;
      margin-bottom: 0;
  }
</style>
